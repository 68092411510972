<template>
  <main class="UsersForm">
    <TheSubHeader 
      icon="profile" 
      :title="UsersFormTitle"
      page-title-tag="Users Form"
    >
      <template #afterButtons>
        <BaseButton
          icon="chevron-left-alt"
          label="voltar"
          theme="icon-left"
          :track="trackSubheaderButtonString('Users Form', 'back')"
          @click="$router.push('/users')"
        />
      </template>
    </TheSubHeader>
    <div class="UsersForm__content">
      <DynamicForm
        class="UsersForm__form"
        :model="form"
        :blueprint="blueprint({transporters, validDocumentField, groupsSelect, isEdit: !!userId, checkAccess: checkAccess, bankDataRules, canEditFields, canDisableUsers, disableFields, dynamicFields})"
        :endpoint-errors="endpointErrors"
        submit-type="json"
        @showStores="showStores"
        @submit="onSubmit"
        @validateUserBankData="validateUserBankData"
        @toggleValidateButton="toggleValidateButton"
        @fetchTypePermissions="fetchTypePermissions"
      >
        <div v-if="invalidBankDataText" class="invalidBankDataText">
          {{ invalidBankDataText }}
        </div>
      </DynamicForm>
    </div>

    <Dialog
      v-if="confirmPasswordModal"
      class="UsersForm__ConfirmPassword"
      warning="Por favor, confirme a senha do seu usuário para salvar os dados."
      label-confirm="Confirmar"
      @cancel="confirmPasswordModal = false"
      @confirm="updateUserWithPasswordConfirmation"
    >
      <InputGroup
        v-model="confirmPassword"
        label="Sua senha"
        autocomplete="no"
        type="password"
      />
    </Dialog>
  </main>
</template>

<script>
import { users } from '@/api'
import { mapActions, mapState } from 'vuex'
import { BaseButton } from '@/components/atoms'
import { DynamicForm, Dialog, InputGroup } from '@/components/molecules'
import { TheSubHeader } from '@/components/organisms'
import blueprint from './UsersForm.blueprint'
import validateBankDataMixin from '@/mixins/validateBankData'
import { groups } from '@/api'

export default {
  name: 'UsersForm',
  components: {
    TheSubHeader,
    DynamicForm,
    BaseButton,
    Dialog,
    InputGroup
  },
  mixins: [validateBankDataMixin],
  data() {
    return {
      form: {
        role_id: null,
        name: '',
        phone: '',
        email: '',
        password: '',
        cpf: '',
        cnpj: '',

        store: [],
        transporter: [],
        transit_point_id: null,

        bank_id: null,
        ag: '',
        account: '',
        type_id: null,
        account_name: '',
        account_document: '',
        account_digit: '',

        dashboard_permission: [],
        orders_permission: [],
        drivers_permission: [],
        transporters_permission: [],
        stores_permission: [],
        users_permission: [],
        status_id: {},
      },
      putCpf: true,
      changeAccess: false,
      endpointErrors: {},
      storesTransporters: false,
      sameUser: false,
      dynamicFields: {},
      hasGroupsPermissions: false,

      confirmPassword: null,
      confirmPasswordModal: false,
      confirmPasswordData: null
    }
  },
  computed: {
    ...mapState({
      groupsSelect: state => state.groups.groupsSelect,
      transporters: state => state.transporters.transporters,
      bankTypes: state => state.drivers.bankTypes,
      permissions: state => state.auth.permissions
    }),

    validDocumentField() {
      if (this.form.cnpj === null) {
        this.cnpjTranform()
        return this.requiredDocuments()
      } else if (this.form.cpf === null) {
        this.cpfTransform()
        return this.requiredDocuments()
      } else {
        return this.requiredDocuments()
      }
    },
    
    userId() {
      return this.$route.params.id
    },

    checkAccess() {
      var changeAccess
      if (this.$route.params.id) {
        if ( this.sameUser && this.form.role_id){
          if ( this.form.role_id.name === 'master' ) {
            changeAccess = false
          } else if ( this.form.role_id.name === 'beneficiario' ) {
            changeAccess = true
          } else if ( this.form.role_id.name === 'operador' ) {
            changeAccess = true
          } else if ( this.form.role_id.name === 'lojista' ) {
            changeAccess = true
          } 
        }
        return changeAccess
      }
      return changeAccess
    },

    canEditFields() {
      const permissions = {
        editType: this.permissions?.usersDetail?.editType,
        editName: this.permissions?.usersDetail?.editName,
        editPhone: this.permissions?.usersDetail?.editPhone,
        editPassword: this.permissions?.usersDetail?.editPassword
      }

      if(this.sameUser && permissions.editType) Object.keys(permissions).forEach(i => permissions[i] = true)

      return permissions
    },

    canDisableUsers() {
      return this.permissions?.usersDetail?.disableUsers
    },

    UsersFormTitle() {
      let isEditTitle = this.userId ? `${this.form?.name} ${this.form?.role_id?.name ? `- ${this.form?.role_id?.name}` : ''}` : 'Cadastro de Operador'
      return this.sameUser ? 'Meu Perfil' : isEditTitle
    },

    disableFields() {
      return !!this.form.role_id && this.hasGroupsPermissions
    }

  },
  async mounted() {
    this.sameUser = parseInt(this.$route.params.id) === parseInt(JSON.parse(localStorage.getItem('user')).id)
    if (this.userId) await this.fetchUser()
    this.fetchGroupSelect()
    this.fetchTransporters()
    this.fetchBankTypes()
  },

  methods: {
    blueprint,
    ...mapActions({
      fetchGroupSelect: 'groups/fetchGroupSelect',
      fetchTransporters: 'transporters/fetchTransporters',
      fetchBankTypes: 'drivers/fetchBankTypes',
      setLoading: 'loading/setLoading',
      setAlert: 'error/setAlert'
    }),

    cnpjTranform() {
      this.form.cnpj = ''
    },
    
    cpfTransform() {
      this.form.cpf = ''
    },

    requiredDocuments() {
      return this.form.cnpj.length === 0 ? this.putCpf : !this.putCpf
    },

    async fetchUser() {
      this.setLoading(true)
      await users.fetchUser({ id: this.userId }, async (res) => {
        this.setDriverBankDataStatus(res)
        
        for (let i in this.form) {
          if (res[i]) this.form[i] = res[i]
        }

        this.form.role_id = res.roles?.[0] ? { name: res.roles?.[0].name, id: res.roles?.[0].id } : ''
        this.form.store = res.stores_pivot
        this.form.transporter = res.transporters_pivot
        this.form.cnpj = res?.documents?.cnpj || ''
        this.form.cpf = res?.documents?.cpf || ''
        this.form.bank_id = res.bank_id || ''
        this.form.ag = res?.bank_data?.ag || ''
        this.form.account = res?.bank_data?.account || ''
        this.form.type_id = res.type_id || ''
        this.form.account_name = res?.bank_data?.account_name || ''
        this.form.account_document = res?.bank_data?.account_document || ''
        this.form.account_digit = res?.bank_data?.account_digit || ''
        this.form.transit_point_id = res?.information?.transit_point

        this.showStoresInUpdatePage(res.store_id)
      
        await this.fetchTypePermissions(this.form.role_id)
      }, (e) => e, () => this.setLoading(false))    
    },

    showStoresInUpdatePage(store_id) {
      if (store_id) this.storesTransporters = true
    },

    async createUser(body) {
      if (!this.userId){
        delete body.status_id
      }
      Object.assign(body, { role_id: this.form.role_id.id } )
      this.setLoading(true)
      await users.createUser(body, () => this.$router.push('/users'), (e) => this.endpointErrors = e.validation, () => this.setLoading(false))
    },

    async updateUser(body) {
      if (body.password === ''){
        delete body.password
      }
      Object.assign(body, { role_id: this.form.role_id.id } )
      this.setLoading(true)
      await users.updateUser({ id: this.userId, body }, async () => {
        await this.fetchUser()
        this.setAlert('Alteração salva com sucesso!')
      }, (e) => this.endpointErrors = e.validation, () => this.setLoading(false))
    },

    showStores(){
      this.storesTransporters = true
    },

    formatData(data) {
      let hasBankInfo = (!!this.form.bank_id)
      if (hasBankInfo) {
        data.bank_data = {}
        data.bank_data.ag = this.form.ag
        data.bank_data.bank_id = this.form.bank_id.id
        data.bank_data.account = this.form.account
        data.bank_data.type_id = this.form.type_id.id
        data.bank_data.account_digit = this.form.account_digit
        data.bank_data.account_name = this.form.account_name
        data.bank_data.account_document = this.form.account_document
      }
      if (this.form.cpf && !this.form.cnpj) delete data.cnpj
      if (this.form.cnpj && !this.form.cpf) delete data.cpf
      if (this.form.transit_point_id) data.transit_point_id = data.transit_point_id.id

      return data
    },

    onSubmit(data) {
      if (this.userId) {
        let confirmPassword = !!data.password
        if (confirmPassword) {
          this.confirmPasswordModal = true
          this.confirmPasswordData = this.formatData(data)
        } else {
          this.updateUser(this.formatData(data))
        }
      } else {
        this.createUser(this.formatData(data))
      }
    },

    updateUserWithPasswordConfirmation() {
      let data = {
        ...this.confirmPasswordData,
        password_confirmation: this.confirmPassword
      }
      this.updateUser(data)
      this.confirmPasswordModal = false
    },

    async fetchTypePermissions(data) {
      if(!data.id) return 
      this.setLoading(true)
      
      await groups.fetchGroupPermissions(data.id, (res) => {  
        this.dynamicFields = {
          beneficiaryFields: this.hasFieldsPermissions(res, 'beneficiaryFields'),
          operatorTransporterFields: this.hasFieldsPermissions(res, 'operatorTransporterFields'),
          transporterFields: this.hasFieldsPermissions(res, 'transporterFields'),
          hubFields: true
        }
        this.hasGroupsPermissions = true
      }, (e) => e, () => this.setLoading(false))
    },

    hasFieldsPermissions(array, type) {
      return !!array.permissions.find(el => el.name.includes(type))
    }
  }
}
</script>

<style lang="scss" scoped>
.UsersForm {
  /deep/ .TheSubHeader__title h1 {
    text-transform: initial;
  }
  
  &__content {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: ". main .";
    grid-column-gap: 1rem;
    margin-bottom: 40px;
  }

  .invalidBankDataText {
    grid-area: invalidBankDataText;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $color-alert-base;
    font-size: $font-size-mini;
    font-weight: $font-weight-bold;
    > div {
      max-width: 390px;
      text-align: justify;
    }
  }

  &__form {
    grid-template-areas:
      "labelDetails labelDetails . . . . . . . . . ."
      "role_id role_id name name name phone phone email email email password password"
      "cpf cpf cpf cnpj cnpj cnpj . . . . . ."
      "bindHubs bindHubs bindHubs bindHubs bindHubs bindHubs bind bind bind bind bind bind"
      "bindStores bindStores bindStores bindStores bindStores bindStores bindStores bindStores bindStores bindStores bindStores bindStores"
      "labelBank labelBank . . . . . . . . . ."
      "account_name account_name account_name account_name account_document account_document account_document . . . . ."
      "bank_id bank_id bank_id ag ag ag account account account_digit type_id type_id type_id"
      "validateUserBankData validateUserBankData validateUserBankData invalidBankDataText invalidBankDataText invalidBankDataText invalidBankDataText invalidBankDataText invalidBankDataText invalidBankDataText invalidBankDataText invalidBankDataText"
      ". . . . . . . . . . . ."
      ". . . . . . . . toggleUser toggleUser confirm confirm";
  }

  &__bind { grid-area: bind }
}
</style>