export default ({transporters, validDocumentField, groupsSelect, isEdit, checkAccess, bankDataRules, canEditFields, canDisableUsers, disableFields, dynamicFields }) => {
  // toggleUser
  const toggleUser = [
    {
      id: 'toggleUser',
      tag: 'ButtonToggle',
      model: 'status_id',
      props: {
        name: 'Usuário'
      },
    },
  ]

  //User General
  const userGeneral = [
    {
      id: 'labelDetails',
      tag: 'label',
      content: 'Dados Básicos'
    },
    {
      id: 'role_id',
      model: 'role_id',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Tipo',
        label: 'Tipo',
        placeholder: 'Selecione',
        invalidLabel: 'Escolha um tipo',
        clearable: false,
        selectOptions: groupsSelect,
        readOnly: !canEditFields.editType
      },
      events: {
        input: 'fetchTypePermissions'
      }
    },
    {
      id: 'name',
      model: 'name',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Nome',
        invalidLabel: 'Insira um nome',
        tabIndex: 1,
        readOnly: !disableFields || !canEditFields.editName
      }
    },
    {
      id: 'phone',
      model: 'phone',
      tag: 'InputGroup',
      rules: ['required', 'phone'],
      props: {
        label: 'Celular',
        mask: 'phone',
        invalidLabel: 'Insira um número de Telefone',
        tabIndex: 2,
        readOnly: !disableFields || !canEditFields.editPhone
      }
    },
    {
      id: 'email',
      model: 'email',
      tag: 'InputGroup',
      rules: ['required', 'email'],
      props: {
        label: 'E-mail',
        invalidLabel: 'Insira um E-mail válido',
        tabIndex: 3,
        readOnly: !disableFields || isEdit
      }
    },
    {
      id: 'password',
      model: 'password',
      tag: 'InputGroup',
      rules: isEdit ? [] : ['required'],
      props: {
        label: isEdit ? 'Nova Senha' : 'Senha',
        type: 'password',
        invalidLabel: 'Insira uma senha',
        tabIndex: 4,
        readOnly: !disableFields || !canEditFields.editPassword
      }
    }
  ]

  //Only to Beneficiario
  const documentsInfo = [
    {
      id: 'cpf',
      model: 'cpf',
      tag: 'InputGroup',
      rules: [ validDocumentField ? 'cpf' : null],
      props: {
        readOnly: checkAccess,
        label: 'CPF',
        mask: 'cpf',
        invalidLabel: 'Insira um CPF válido ',
        tabIndex: 5
      }
    },
    {
      id: 'cnpj',
      model: 'cnpj',
      tag: 'InputGroup',
      rules: [ !validDocumentField ? 'cnpj' : null],
      props: {
        readOnly: checkAccess,
        label: 'CNPJ',
        mask: 'cnpj',
        invalidLabel: 'Insira um CNPJ válido',
        tabIndex: 6
      }
    }
  ]

  //Transporters
  const searchHubs = [{
    id: 'transit_point_id',
    model: 'transit_point_id',
    tag: 'BaseTreeSelect',
    style: 'grid-area: bindHubs',
    props: {
      readOnly: checkAccess,
      label: 'Vincular HUB',
      noOptionsText: 'Nome do HUB',
      placeholder: 'Pesquisar HUBs',
      multiple: false,
      tags: true,
      preserveHeight: true,
      selectOptions: null,
      fetchOptions: 'stores/fetchHubsAsync',
      tabIndex: 8
    }
  }]

  //Transporters
  const searchTransporters = [{
    id: 'transporter',
    model: 'transporter',
    tag: 'BaseTreeSelect',
    style: 'grid-area: bind',
    rules: ['required'],
    props: {
      name: 'Vincular Transportadora',
      label: 'Vincular Transportadora',
      placeholder: 'Selecione',
      invalidLabel: 'Vincule ao menos uma transportadora',
      multiple: true,
      tags: true,
      preserveHeight: true,
      selectOptions: transporters,
      readOnly: checkAccess,
      tabIndex: 7
    },
    events: {
      input: 'showStores'
    }
  }]

  //Stores
  const searchStores = [{
    id: 'store',
    model: 'store',
    tag: 'BaseTreeSelect',
    style: 'grid-area: bindStores',
    rules: ['required'],
    props: {
      readOnly: checkAccess,
      label: 'Vincular Lojistas',
      noOptionsText: 'Nome do Lojista',
      placeholder: 'Pesquisar Lojistas',
      multiple: true,
      tags: true,
      preserveHeight: true,
      selectOptions: null,
      fetchOptions: 'stores/fetchStoresAsync',
      tabIndex: 8
    }
  }]

  //Bank
  const bankInfo = [{
    id: 'labelBank',
    tag: 'label',
    content: 'Dados Bancários'
  },
  {
    id: 'account_name',
    model: 'account_name',
    tag: 'InputGroup',
    rules: ['required'],
    props: {
      label: 'Nome da pessoa ou empresa da Conta',
      invalidLabel: 'Insira um nome',
      tabIndex: 10
    },
    events: {
      input: 'toggleValidateButton'
    }
  },
  {
    id: 'account_document',
    model: 'account_document',
    tag: 'InputGroup',
    rules: ['required', 'cpfCnpj'],
    props: {
      label: 'CPF ou CNPJ da Conta',
      mask: 'cpfCnpj',
      invalidLabel: 'Insira um CPF ou CNPJ válido',
      tabIndex: 11
    },
    events: {
      input: 'toggleValidateButton'
    }
  },
  {
    id: 'bank_id',
    model: 'bank_id',
    tag: 'BaseTreeSelect',
    rules: ['required'],
    props: {
      name: 'Banco',
      label: 'Banco',
      placeholder: 'Selecione',
      invalidLabel: 'Insira um banco',
      selectOptions: null,
      fetchOptions: 'drivers/fetchBanks',
      tabIndex: 12
    },
    events: {
      input: 'toggleValidateButton'
    }
  },
  {
    id: 'ag',
    model: 'ag',
    tag: 'InputGroup',
    rules: ['required'],
    props: {
      label: 'Agência',
      mask: 'numberHyphen',
      replaceMaskOnKeyUp: false,
      invalidLabel: 'Insira o número da Agência',
      tabIndex: 13
    },
    events: {
      input: 'toggleValidateButton'
    }
  },
  {
    id: 'account',
    model: 'account',
    tag: 'InputGroup',
    rules: ['required'],
    props: {
      label: 'Conta',
      mask: 'numberHyphen',
      replaceMaskOnKeyUp: false,
      invalidLabel: 'Insira o número de Conta',
      tabIndex: 14
    },
    events: {
      input: 'toggleValidateButton'
    }
  },
  {
    id: 'account_digit',
    model: 'account_digit',
    tag: 'InputGroup',
    props: {
      label: 'Dígito',
      mask: 'number',
      replaceMaskOnKeyUp: false,
      tabIndex: 15
    },
    events: {
      input: 'toggleValidateButton'
    }
  },
  {
    id: 'type_id',
    model: 'type_id',
    tag: 'BaseTreeSelect',
    rules: ['required'],
    props: {
      name: 'Tipo',
      label: 'Tipo',
      placeholder: 'Selecione',
      invalidLabel: 'Insira um tipo de banco',
      selectOptions: null,
      fetchOptions: 'drivers/fetchBankTypes',
      tabIndex: 16
    },
    events: {
      input: 'toggleValidateButton'
    }
  },
  ...(isEdit ? [{
    id: 'validateUserBankData',
    tag: 'BaseButton',
    props: {
      label: bankDataRules.notValidated ? (bankDataRules.disableBankDataEdit ? 'Dados bancários em validação' : 'Validar dados bancários') : 'Dados bancários validados',
      theme: 'icon-right',
      color: bankDataRules.notValidated ? 'alternate' : 'primary',
      textColor: 'white',
      icon: bankDataRules.notValidated ? 'save' : 'check',
      filled: true,
      disabled: bankDataRules.disableBankDataEdit || !bankDataRules.disableValidateButton,
      readOnly: !bankDataRules.notValidated
    },
    events: {
      click: 'validateUserBankData'
    }
  }] : []),
  ]

  //Actions
  const actions = [
    {
      id: 'confirm',
      tag: 'BaseButton',
      props: {
        label: 'Salvar Alterações',
        theme: 'icon-right',
        color: 'primary-base',
        textColor: 'white',
        icon: 'save',
        type: 'submit',
        filled: true
      }
    }
  ]

  return [
    ...userGeneral,
    ...(dynamicFields?.beneficiaryFields ? documentsInfo: []),
    ...(dynamicFields?.beneficiaryFields || dynamicFields?.operatorTransporterFields || dynamicFields?.transporterFields ? searchTransporters : []),
    ...(dynamicFields?.beneficiaryFields || dynamicFields?.operatorTransporterFields ? searchStores : []),
    ...(dynamicFields?.hubFields ? searchHubs : []),
    ...(dynamicFields?.beneficiaryFields ? bankInfo : []),
    ...(canDisableUsers && isEdit ? toggleUser : []),
    ...actions
  ]
}